import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyYouTubeVideo = lazy(
  () => import("./you-tube-video-CsFtP1LX.js").then((module) => ({
    default: module.YouTubeVideo
  }))
);
function YouTubeVideo({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyYouTubeVideo, { ...props });
}
const useYouTubeVideoContent = () => {
  return useMemo(
    () => ({
      youtubeVideo: {
        component: YouTubeVideo
      }
    }),
    []
  );
};
export {
  LazyYouTubeVideo as YouTubeVideo,
  useYouTubeVideoContent
};
